import { env } from "@/next.config";
import httpUtils from "@/utils/httpUtils";

export async function getStartupList(config) {
    return httpUtils.get(`${env.API_VERSION}/startup`, config);
}

export async function getMyStartupList(config) {
    return httpUtils.get(`${env.API_VERSION}/startup/my-list`, config);
}
  
export async function getStartupDetail(id) {
    return httpUtils.get(`${env.API_VERSION}/startup/${id}`);
}

export async function getStartupDetailSimple(id) {
    return httpUtils.get(`${env.API_VERSION}/startup/${id}/simple`);
}

export async function getStartupNews(id, config) {
    return httpUtils.get(`${env.API_VERSION}/startup/${id}/news`, config);
}

export async function getStartupRelated(id, config) {
    return httpUtils.get(`${env.API_VERSION}/startup/${id}/related`, config);
}

export async function searchStartup(config) {
    return httpUtils.get(`${env.API_VERSION}/search-startup`, config);
}

export async function searchStartupAll(config) {
    return httpUtils.get(`${env.API_VERSION}/simple-search-startup-all`, config);
}

export async function createStartup(payload) {
    return httpUtils.post(`${env.API_VERSION}/startup`, payload);
}

export async function createStartupByUser(payload) {
    return httpUtils.post(`${env.API_VERSION}/startup/create-by-user`, payload);
}

export async function updateStartup(id, payload) {
    return httpUtils.put(`${env.API_VERSION}/startup/${id}`, payload);
}

export async function updatePublishStartup(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/startup/${id}`, payload);
}

export async function updateImageStartup(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/startup/${id}/image`, payload);
}

export async function updateSummaryStartup(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/startup/${id}/summary`, payload);
}

export async function updateToClaim(id, payload) {
    return httpUtils.patch(`${env.API_VERSION}/startup/${id}/to-claim`, payload);
}

export async function addTeamMember(payload) {
    return httpUtils.post(`${env.API_VERSION}/startup-member`, payload);
}

export async function updateTeamMember(id, payload) {
    return httpUtils.put(`${env.API_VERSION}/startup-member/${id}`, payload);
}

export async function deleteTeamMember(id) {
    return httpUtils.delete(`${env.API_VERSION}/startup-member/${id}`);
}

export async function addFundingRound(payload) {
    return httpUtils.post(`${env.API_VERSION}/funding-round`, payload);
}

export async function updateFundingRound(id, payload) {
    return httpUtils.put(`${env.API_VERSION}/funding-round/${id}`, payload);
}

export async function deleteFundingRound(id) {
    return httpUtils.delete(`${env.API_VERSION}/funding-round/${id}`);
}

export async function addVcStartupNews(payload) {
    return httpUtils.post(`${env.API_VERSION}/startup/create-vc-startup-news`, payload);
}

export async function editVcStartupNews(id, payload) {
    return httpUtils.put(`${env.API_VERSION}/startup/${id}/create-vc-startup-news`, payload);
}

export async function getVcStartupNews(startupId) {
    return httpUtils.get(`${env.API_VERSION}/startup/${startupId}/startup-news`);
}

export async function getStartupSectors(startupId) {
    return httpUtils.get(`${env.API_VERSION}/startup/${startupId}/sectors`);
}

export async function createPitchDeckRequest(payload) {
    return httpUtils.post(`${env.API_VERSION}/startup/create-pitch-deck-request`, payload);
}

export async function checkStartupExist(params) {
    return httpUtils.get(`${env.API_VERSION}/check-startup-exist`, { params });
}

export async function mostViewedStartups(params) {
    return httpUtils.get(`${env.API_VERSION}/startup/most-viewed`, { params });
}

export function trackStartupAccessCounting(id, payload) {
    return httpUtils.post(`${env.API_VERSION}/startup/increment-count/${id}`, payload);
}

export async function getStartupUsers(config) {
    return httpUtils.get(`${env.API_VERSION}/startup-invitations/get-startup-users`, config);
}

export async function getAllStartupAccesses(startupId, config) {
    return httpUtils.get(`${env.API_VERSION}/startup-invitations/${startupId}`, config);
}

export async function sendStartupAccessInvitation(payload) {
    return httpUtils.post(`${env.API_VERSION}/startup-invitations`, payload);
}

export async function acceptInvitation(uuid) {
    return httpUtils.post(`${env.API_VERSION}/startup-invitations/${uuid}/approve`);
}

export async function getAccessByStartupId(startupId, config) {
    return httpUtils.get(`${env.API_VERSION}/startup-invitations/${startupId}/get-access`, config);
}

export async function removeAccess(id, config) {
    return httpUtils.delete(`${env.API_VERSION}/startup-invitations/remove-access/${id}`, config);
}

export async function showStartupForUser(config) {
    return httpUtils.get(`${env.API_VERSION}/startup-invitations/show-startup-for-user`, config);
}

export async function getLastYearStartupStockPrice(ticker, selected_range) {
    return httpUtils.get(`${env.API_VERSION}/stock-prices/${ticker}/last-year/${selected_range}`);
}
